import React from "react";
import BoxModulo from "./box-modulo";

const BoxModuli = ({ items, plan }) => {
  return (
    <section id="moduli" className="section">
      <div className="section__border">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-md-3">
              <h2 className="text-md-end">
                Moduli <span className="color-highlighted">compresi</span>
              </h2>
            </div>
            <div className="col-12 col-md-7 offset-md-1">
              {plan && (
                <div className="h4 mb-3">
                  Cosa trovi in <strong className="color-highlighted">{plan}</strong>
                </div>
              )}
              <p>
                {items.map((item, i, res) => (
                  <span key={i}>
                    {item.name}
                    {i + 1 === res.length ? (
                      <>
                        . <br />{" "}
                      </>
                    ) : (
                      <>, </>
                    )}
                  </span>
                ))}
                Tutto il necessario "ready designed” e “mobile responsive” per un progetto di
                Intranet moderno e funzionale.
              </p>
            </div>
          </div>
        </div>
      </div>
      {items.map((item, i) => (
        <div key={i}>
          <BoxModulo items={item} />
        </div>
      ))}
    </section>
  );
};

export default BoxModuli;
