import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BoxModulo = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`box-modulo${isOpen ? " box-modulo--open" : ""}`}>
      <div className="container-lg">
        <div className="row">
          <div className="col-12 col-md-4 d-none d-md-block">
            <GatsbyImage
              image={getImage(items.image)}
              alt={items.name}
              className="box-modulo__img"
              loading="eager"
            />
          </div>
          <div className="col-12 col-md-8">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="box-modulo__name color-highlighted accordion-click-tracking-ga"
              data-ga-id={`accordion ${items.name}`}
            >
              {items.name}
            </button>
            {items.children && items.children.length && (
              <div className="box-modulo__desc">
                <div>
                  <div className="row">
                    {items.children.map((child, j) => (
                      <div key={j} className="col-12 col-md-6">
                        <div className="box-modulo__title">{child.name}</div>
                        {child.desc && (
                          <div
                            dangerouslySetInnerHTML={{ __html: child.desc }}
                            className="box-modulo__text"
                          ></div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxModulo;
