import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`box-accordion${isOpen ? " box-accordion--open" : ""}`}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="box-accordion__title accordion-click-tracking-ga"
        data-ga-id={`accordion ${items.name}`}
      >
        {items.name}
      </button>
      <div className="box-accordion__content">
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: items.desc }}
            className="box-accordion__text"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
