import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import BoxFeature from "../components/box-feature";
import BoxTab from "../components/box-tab";
import Moduli from "../components/box-moduli";
import SliderServizi from "../components/slider-servizi";
import Accordion from "../components/box-accordion";
import SliderReferenze from "../components/slider-referenze";

const Starter = ({ data }) => {
  const { hero, starter, referenze } = data;

  const referenzeStarter = referenze.nodes.filter((plan) => plan.starter);

  return (
    <Layout>
      <Seo
        title={`Intranet 80.20 Starter | Economica e pronta all'uso`}
        description={`Intranet 80.20 Starter: l'ideale per la tua prima intranet. Funzionalità essenziali, facile da gestire e modulabile secondo le esigenze della tua azienda.`}
      />

      <div className="page-product page-product--starter">
        <div className="hero">
          <div className="container-lg">
            <div className="row">
              <div className="col-12 order-2 order-sm-1 col-sm-4 col-md-5">
                <GatsbyImage
                  image={getImage(hero)}
                  alt="img hero"
                  objectPosition="center center"
                  className="hero__img mb-xl-5"
                />
              </div>
              <div className="col-12 order-1 order-sm-2 col-sm-8 col-md-7">
                <div className="hero__claim hero__claim--starter d-flex flex-column h-100">
                  <h1 className="hero__title">
                    È la tua <strong>prima esperienza</strong> con una{" "}
                    <strong>Intranet aziendale?</strong>
                  </h1>
                  <div className="mt-auto text-md-end">
                    <h2 className="mb-0">
                      Intranet 80.20 <span className="color-highlighted">Starter</span>
                    </h2>
                    <h3 className="mb-0">
                      è la <strong className="color-highlighted">soluzione ideale</strong>,{" "}
                      <strong className="color-highlighted">economica</strong> e pronta all'uso.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--starter">
            <div className="text-scroll text-scroll--primary" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase starter">Starter</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase starter">Starter</span>
              </div>
            </div>
          </div>
        </div>

        <BoxFeature items={starter.feature} />

        <div>
          <BoxTab />

          <Moduli items={starter.moduli} plan="Intranet 80.20 Starter?" />

          <SliderServizi
            items={starter.servizi}
            claimText="<p>Potenzia la tua piattaforma Starter, aggiungi moduli in base ai tuoi obiettivi.</p>"
          />

          <section id="esigenze" className="section">
            <div className="container-lg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2>
                    Cosa puoi fare con{" "}
                    <span className="color-highlighted">Intranet 80.20 Starter</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {starter.cosafare.map((item, i) => (
                    <div key={i}>
                      <Accordion items={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>

        <SliderReferenze items={referenzeStarter} title="Referenze" />
      </div>
    </Layout>
  );
};

export default Starter;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "starter.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
      }
    }
    starter: starterJson {
      feature {
        name
        desc
      }
      moduli {
        name
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        children {
          name
          desc
        }
      }
      servizi {
        name
        desc
        liferay
        microsoft
      }
      cosafare {
        name
        desc
      }
    }
    referenze: allContentfulReferenza(sort: { fields: date, order: DESC }) {
      nodes {
        ...ReferenzaFragment
      }
    }
  }
`;
