import React from "react";
import { Link } from "./link";

const BoxTab = () => {
  return (
    <div className="box-tab">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-wrap justify-content-center">
              <Link to={`#moduli`} className="box-tab__btn text-nowrap">
                Moduli compresi
              </Link>
              <Link to={`#servizi`} className="box-tab__btn text-nowrap">
                Servizi opzionali
              </Link>
              <Link to={`#esigenze`} className="box-tab__btn text-nowrap">
                A quali esigenze risponde
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxTab;
