import React from "react";
import Slider from "react-slick";
import { Link } from "./link";

import BoxReferenza from "../components/box-referenza";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  fade: false,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SliderReferenze = ({ items, title }) => {
  return (
    <section id="referenze" className="section section--referenze section--dark">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              {title && <h2 className="mb-0">{title}</h2>}
              <Link to="/referenze/" title="Vedi tutte le referenze" className="btn-link">
                <span>Vedi tutte</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings} className="slider-referenze">
              {items.slice(0, 10).map((item, i) => (
                <div key={i}>
                  <BoxReferenza data={item} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderReferenze;
