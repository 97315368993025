import React from "react";
import Slider from "react-slick";

import Half8Light from "../icons/half-8-light.svg";
import Half2Solid from "../icons/half-2-solid.svg";
import Liferay from "../icons/logo-liferay.svg";
import Microsoft from "../icons/logo-microsoft.svg";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  fade: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SliderServizi = ({ items, claimText }) => {
  return (
    <>
      <section id="servizi" className="section section--servizi section--dark">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-md-2">
                Servizi <span className="color-highlighted">opzionali.</span>
              </h2>
              {claimText && (
                <div className="section__claim">
                  <div
                    dangerouslySetInnerHTML={{ __html: claimText }}
                    className="section__claim"
                  ></div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-relative">
              <Half8Light className="ico-svg ico-half-8-light" />
              <Slider {...settings} className="slider-servizi">
                {items.map((item, i) => (
                  <div key={i}>
                    <div className="box-servizio">
                      <Half2Solid className="ico-svg ico-half-2-solid color-highlighted" />
                      <div className="d-flex flex-column align-items-start h-100">
                        <div className="box-servizio__name mb-1">{item.name}</div>
                        {item.desc && (
                          <div
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                            className="box-servizio__desc"
                          ></div>
                        )}
                        <div className="box-servizio__available d-flex align-items-center mt-auto">
                          <span>Acquistabile per:</span>{" "}
                          {item.liferay && (
                            <>
                              <span title="Liferay">
                                <Liferay className="ico-svg" />
                              </span>
                            </>
                          )}
                          {item.microsoft && (
                            <>
                              <span title="Microsoft 365">
                                <Microsoft className="ico-svg" />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderServizi;
