import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const BoxReferenza = ({ data }) => {
  return (
    <div className="box-referenza">
      <GatsbyImage
        image={getImage(data.image)}
        alt={data.name}
        className="box-referenza__img"
        loading="eager"
      />
      <GatsbyImage
        image={getImage(data.logo)}
        alt={`logo ${data.name}`}
        className="box-referenza__logo"
        loading="eager"
      />
      <div className="box-referenza__text">
        <div
          dangerouslySetInnerHTML={{ __html: data.claim?.childMarkdownRemark?.html }}
          className="box-referenza__claim"
        ></div>
      </div>
    </div>
  );
};

export default BoxReferenza;

export const referenzaFragment = graphql`
  fragment ReferenzaFragment on ContentfulReferenza {
    name
    image {
      title
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    logo {
      title
      gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
    }
    claim {
      childMarkdownRemark {
        html
      }
    }
    starter
    enterprise
    extranet
  }
`;
