import React from "react";

const BoxFeature = ({ items }) => {
  return (
    <section id="feature" className="section py-0">
      <div className="container-lg">
        <div className="row">
          {items.map((item, i) => (
            <div key={i} className="col-12 col-md-4">
              <div className="box-feature">
                <div className="box-feature__title">{item.name}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                  className="box-feature__text"
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BoxFeature;
